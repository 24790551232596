<template>
  <van-popup 
    v-model:show="show"
    round
    :close-on-click-overlay="false"
    @click-overlay="close"
  >
    <div class="tier-popup">
      <van-icon class="close-icon" name="cross" @click="close"/>
      <div class="tier-title">{{`Tier Benefits`.toLocaleUpperCase()}}</div>
      <div class="tier-list">
        <div
          v-for="item in rankList" 
          class="tier-benefit-item"
        >
          <van-icon :name="item.icon" />
          <div class="tier-level">{{item.name}}</div>
          <ul class="benefit-list">
            <li v-for="info in item.privilegeInfoList">{{info}}</li>
          </ul>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
  
export default {
  name: 'TierBenefit',
  props: {
    show: Boolean,
    rankList: Object,
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('hide')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.tier-popup {
  position: relative;
  width: 324px;
  padding: 24px 4px 12px;
  text-align: center;
  overflow: hidden;
  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    color: #202020;
  }
  .tier-title {
    margin: 12px 0 20px;
    line-height:20px;
    color: #202020;
    font-size: 18px;
    font-weight: 500;
  }
  .tier-list {
    padding: 0 24px;
    max-height: 60vh;
    margin: 8px 0;
    overflow: scroll;
    .tier-benefit-item {
      position: relative;
      min-height: 54px;
      padding: 0 0 0 54px;
      margin-bottom: 10px;
      text-align: left;
      .van-icon {
        position: absolute;
        left: 4px;
        top: 10px;
        font-size: 36px;
      }
      .tier-level {
        font-size: 12px;
        font-weight: 700;
        color: #202020;
        margin-bottom: 4px;
      }
      .benefit-list {
        padding-left: 18px;
        li {
          list-style: disc;
          white-space: pre-wrap;
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>