<template>
  <div class="mine" :style="`background: ${$AppData.config.appPageInfoList.filter(item => item.code === 'MineFragment')[0]?.backgroundColor || '#f6f8fb'} url(${$AppData.config.backgroundImgUrl}) 0 0/100% auto no-repeat;`">
    <!-- <div class="user-info-wrap">
      <div class="user-header-title">{{maskUsername}}</div>
      <van-icon class="exit-btn" name="/images/logout.png" size="20" color="#fff" @click="signOut"/>
    </div> -->
    <div class="rank-wrap">
      <div class="rank-card" :style="`background: url( ${currentRank.backgroundImg}) 0 0/100% auto no-repeat;`">
        <div class="rank-type">{{currentRank.name}}</div>
        <div class="rank-type-title">{{$t('tier.currentTier')}}</div>
        <div class="register-time">{{ $t('tier.memberSinceTime').format({ 1: $moment(rankInfo.rankTime * 1000).format('MMM DD,YYYY') }) }}</div>
        <div class="username">{{maskUsername}}</div>
      </div>
      <div class="btn-bar">
        <van-button
          size="small"
          type="primary"
          @click="showTierLevel = true"
        >{{$t('tier.levelBtn')}}</van-button>
        <van-button
          size="small"
          type="primary"
          plain
          @click="showTierBenefit = true"
        >{{$t('tier.benefitBtn')}}</van-button>
      </div>
      <div class="rank-type-list">
        <div 
          v-for="item in rankList"
          key="rank"
          class="rank-item"
        >
          <van-icon :name="item.rank <= rankInfo.rank ? item.icon : item.disabledIcon" />
          <p>{{item.name}}</p>
        </div>
      </div>
      <div v-if="rankInfo.requireList.length > 0" class="rank-process-wrap">
        <div v-for="item in rankInfo.requireList" class="rank-process-item">
          <van-progress
            pivot-text=""
            :color="$primary"
            track-color="#cacacc"
            :percentage="item.current / item.target * 100"
          />
          <div class="rank-process-type">{{item.name}}</div>
          <div class="rank-process-text">{{`${item.current}/${item.target}`}}</div>
        </div>
      </div>
      <div v-else class="rank-tip">{{$t('tier.congratulations')}}</div>
    </div>
    <pagelist page="MineFragment" />
    <van-button class="logout-btn" type="primary" @click="signOut">{{$t('button.logout')}}</van-button>
    <tierlevel :show="showTierLevel" :rankList="rankList" @hide="showTierLevel = false" />
    <tierbenefit :show="showTierBenefit" :rankList="rankList" @hide="showTierBenefit = false" />
  </div>
</template>

<script>
import { ref } from 'vue';
import i18n from '@/assets/lang/index';
import pagelist from '@/components/PageList';
import tierlevel from '@/components/TierLevel';
import tierbenefit from '@/components/TierBenefit';

import { queryRankInfo, loginOut } from "@/api";
// import config from '../../../package.json'

export default {
  components: {
    pagelist,
    tierlevel,
    tierbenefit
  },
  
  setup() {
    const cellThemeVars = {
      cellVerticalPadding: '13px',
    };
    return {
      cellThemeVars
    };
  },
  
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      rankList: [],
      rankInfo: {
        rank: 0,
        rankTime: 0,
        requireList: [],
      },
      showTierLevel: false,
      showTierBenefit: false,
    };
  },
  
  computed: {
    maskUsername() {
      let username = this.userInfo.username || '';
      if (username.length > 9) {
        username = username.substring(0, 5) + '****' +username.substring(username.length-4, username.length)
      }
      return username;
    },
    currentRank() {
      const list = this.rankList.filter(item => item.rank === this.rankInfo.rank);
      if (list.length > 0) {
        return list[0];
      } else {
        return {};
      }
    },
  },
  
  mounted() {
    this.fetchRankInfo();
  },
  
  methods: {
    fetchRankInfo() {
      queryRankInfo().then(res => {
        const response = res.data || {};
        this.rankList = response.infoList || [];
        this.rankInfo = {
          rank: response.rank,
          rankTime: response.rankTime,
          requireList: response.requireList || [],
        }
      });
    },
    signOut() {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.default'),
        message: i18n.global.t('alert.logout'),
      })
      .then(() => {// on confirm
        loginOut().then(res => {});
        setTimeout(() => {
          this.$cookie.clearCookie('token');
          this.$router.replace('/login');
          if (window.jsBridge) {
            window.jsBridge.invokeNative('clearWebCache', '', '');
          }
        }, 100);
      })
      .catch(() => {});
    },
  },
};
</script>

<style lang="less">
.mine {
  min-height: 100vh;
  padding: 0 8px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .user-info-wrap {
    line-height: 26px;
    padding: 40px 20px 20px;
    color: #fff;
    // background: var(--van-primary-color);
    .user-header-title {
      font-size: 21px;
      font-weight: 600;
    }
    .exit-btn {
      position: absolute;
      top: 44px;
      right: 20px;
    }
  }
  .rank-wrap {
    padding: 28px 20px 0;
  }
  .rank-card {
    padding: 18px;
    border-radius: 12px;
    font-size: 12px;
    text-align: right;
    color: #fff;
    .rank-type {
      line-height: 26px;
      margin-bottom: 8px;
      font-size: 20px;
      font-weight: bold;
    }
    .rank-type-title {
      margin-bottom: 60px;
    }
    .username {
      line-height: 18px;
      margin-top: 8px;
      font-size: 15px;
    }
  }
  .btn-bar {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    .van-button {
      flex: 1;
      height: 24px;
      border-radius: 8px;
      &.van-button--plain {
        margin-left: 16px;
        border: 1px solid @primary-color;
      }
    }
  }
  .rank-type-list {
    display: flex;
    justify-content: space-between;
    color: @primary-color;
    margin: 30px 0 22px;
    .rank-item {
      flex: 1;
      text-align: center;
      .van-icon {
        font-size: 40px;
      }
      p { 
        font-size: 12px;
        font-weight: 500;
      }
    }
    
  }
  .rank-process-wrap {
    display: flex;
    .rank-process-item {
      position: relative;
      flex: 1;
      margin-left: 14px;
      &:first-child { margin-left: 0; }
      .van-progress {
        height: 14px;
        border-radius: 7px;
      }
      .rank-process-type {
        line-height: 18px;
        font-size: 11px;
        font-weight: 500;
        text-align: center;
        color: #3d3d3d;
      }
      .rank-process-text {
        position: absolute;
        top: 0;
        right: 8px;
        height: 14px;
        line-height: 14px;
        font-size: 10px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
  .rank-tip {
    font-size: 12px;
    color: #000;
    text-align: center;
  }
  .logout-btn {
    // display: block;
    width: 334px;
    margin: 20px;
  }
}
</style>
