<template>
  <van-popup 
    v-model:show="show"
    round
    :close-on-click-overlay="false"
    @click-overlay="close"
  >
    <div class="tier-popup">
      <van-icon class="close-icon" name="cross" @click="close"/>
      <div class="tier-title">{{`Tier Levels`.toLocaleUpperCase()}}</div>
      <div class="tier-list">
        <div
          v-for="item in rankList" 
          class="tier-level-item"
          :style="`background: ${item.color || $primary };`"
        >
          <div class="left-wrap">
            <van-icon :name="item.icon" />
            <p>{{item.name}}</p>
          </div>
          <ul class="right-wrap">
            <li v-for="info in item.requireInfoList">{{info}}</li>
          </ul>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
  
export default {
  name: 'TierLevel',
  props: {
    show: Boolean,
    rankList: Object,
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('hide')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.tier-popup {
  position: relative;
  width: 324px;
  padding: 24px 4px 12px;
  text-align: center;
  overflow: hidden;
  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    color: #202020;
  }
  .tier-title {
    margin: 12px 0 20px;
    line-height:20px;
    color: #202020;
    font-size: 18px;
    font-weight: 500;
  }
  .tier-list {
    padding: 0 24px;
    max-height: 60vh;
    margin: 8px 0;
    overflow-y: scroll;
    .tier-level-item {
      display: flex;
      align-items: center;
      padding: 14px 0;
      margin-bottom: 10px;
      color: #fff;
      border-radius: 6px;
    }
    .left-wrap {
      width: 112px;
      .van-icon { font-size: 32px;}
      p { font-size: 14px; font-weight: 500;}
    }
    .right-wrap {
      display:flex;
      flex-direction:column;
      justify-content:center;
      width: 124px;
      min-height: 54px;
      padding: 0 12px 0 26px;
      text-align: left;
      border-left: 1px solid #fff;
      li {
        list-style: disc;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
  }
}
</style>